import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/gregoirethibaud/Desktop/Brusketa/Developpement/brusketa/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Avatar from './Avatar';
import { Playground, Props } from 'docz';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "avatar"
    }}>{`Avatar`}</h1>
    <p>{`Avatar component render an avatar composed of two letters (the initials of the user). User firstName and lastName are obtained through `}<inlineCode parentName="p">{`userContext`}</inlineCode>{`. By default, 'AA' initials are displayed.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Avatar} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Avatar value=\"GT\" />'} __scope={{
      props,
      DefaultLayout,
      Avatar,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Avatar value="GT" mdxType="Avatar" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      